import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as css from "../styles/Directors.module.scss";
import ButtonBase from "../components/ButtonBase";

import I18n, { LocaleLink } from "../components/I18n";

const Directors = ({
  pageContext: { locale, locales },
  location: { pathname },
  data: { strapi: { theme = {}, directors: { data: directors = [] } } = {} },
}) => {
  console.log(directors);
  return (
    <Layout
      theme={theme}
      pathname={pathname}
      className={css.Directors}
      locale={locale}
      locales={locales}
    >
      <I18n id="title.directors" component="h1" />
      {directors
        .filter(({ attributes: { image } }) => image)
        .map(({ id, attributes: { slug, image, firstName, lastName } }) => (
          <article className={css.Director} key={id}>
            <ButtonBase component={LocaleLink} to={`/personne/${slug}`}>
              <GatsbyImage image={getImage(image?.data?.attributes?.fileS3)} />
            </ButtonBase>
            <p>{[firstName, lastName].filter((v) => v).join(" ")}</p>
          </article>
        ))}
    </Layout>
  );
};

export const query = graphql`
  query ($locale: Strapi_I18NLocaleCode!) {
    strapi {
      directors: artists(
        locale: $locale
        filters: { creations: { productor: { eq: "pelleas" } } }
      ) {
        data {
          id
          attributes {
            slug
            # triName: tri_name
            firstName: first_name
            lastName: last_name
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "small") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Directors;
